// .App {
//   text-align: center;
// }

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.title {
  margin-left: 40px;
}

.App-link {
  color: #09d3ac;
}
