body {
  background: #f0f1f4;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.short-text {
  display: none; }

@media (max-width: 768px) {
  .short-text {
    display: inline-block; }
  .full-text {
    display: none; } }

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.title {
  margin-left: 40px; }

.App-link {
  color: #09d3ac; }

